var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.popoverTarget)?_c('user-callout-dismisser',{attrs:{"feature-name":"duo_chat_callout"},scopedSlots:_vm._u([{key:"default",fn:function({ dismiss, shouldShowCallout }){return [(shouldShowCallout)?_c('gl-popover',{attrs:{"target":_vm.popoverTarget,"show":shouldShowCallout,"show-close-button":"","css-classes":[
        'js-duo-chat-callout-popover',
        'duo-chat-callout-popover',
        'gl-max-w-48',
        'gl-shadow-lg',
        'gl-p-2',
      ],"triggers":"manual","data-testid":"duo-chat-promo-callout-popover"},on:{"close-button-clicked":function($event){return _vm.dismissCallout(dismiss)}}},[_c('img',{staticClass:"gl-pointer-events-none gl-absolute gl-left-0 gl-top-0 gl-w-full",attrs:{"src":_vm.$options.DUO_CHAT_ILLUSTRATION,"alt":''}}),_vm._v(" "),_c('h5',{staticClass:"gl-my-3 gl-mr-3"},[_vm._v("\n        "+_vm._s(_vm.$options.i18n.POPOVER_LABEL)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"gl-m-0 gl-w-7/10",attrs:{"data-testid":"duo-chat-callout-description"}},[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.POPOVER_DESCRIPTION},scopedSlots:_vm._u([{key:"link",fn:function({ content }){return [_c('gl-link',{attrs:{"href":_vm.$options.learnHowPath},on:{"click":function($event){return _vm.trackEvent('click_learn_how_link_duo_chat_callout')}}},[_vm._v(_vm._s(content))])]}}],null,true)})],1),_vm._v(" "),_c('ul',{staticClass:"gl-w-3/4 gl-pl-5 gl-pt-3"},_vm._l((_vm.$options.i18n.POPOVER_LIST_ITEMS),function(item){return _c('li',{key:item},[_vm._v(_vm._s(item))])}),0),_vm._v(" "),_c('gl-button',{ref:"popoverLink",staticClass:"gl-w-full",attrs:{"variant":"confirm","category":"primary"},on:{"click":function($event){return _vm.dismissAndNotify(dismiss)}}},[_vm._v("\n        "+_vm._s(_vm.$options.i18n.POPOVER_BUTTON)+"\n      ")])],1):_vm._e()]}}],null,false,2439489523)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }